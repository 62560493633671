@font-face {
	font-family: "tt_commons__bold";
	src: url("../fonts/tt_commons_trial__bold.woff2") format("woff2"),url("../fonts/tt_commons_trial__bold.woff") format("woff");
	font-display: swap;
	font-stretch: normal;
	font-style: normal;
	font-weight: normal;
}

@font-face {
	font-family: "tt_commons__medium";
	src: url("../fonts/tt_commons_trial__medium.woff2") format("woff2"),url("../fonts/tt_commons_trial__medium.woff") format("woff");
	font-display: swap;
	font-stretch: normal;
	font-style: normal;
	font-weight: normal;
}

@font-face {
	font-family: "tt_commons__book";
	src: url("../fonts/tt_commons_trial__book.woff2") format("woff2"),url("../fonts/tt_commons_trial__book.woff") format("woff");
	font-display: swap;
	font-stretch: normal;
	font-style: normal;
	font-weight: normal;
}

@font-face {
	font-family: "tt_commons__book_italic";
	src: url("../fonts/tt_commons_trial__book_italic.woff2") format("woff2"),url("../fonts/tt_commons_trial__book_italic.woff") format("woff");
	font-display: swap;
	font-stretch: normal;
	font-style: normal;
	font-weight: normal;
}

a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
	margin: 0;
	padding: 0;
	border: none;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
	display: block;
}

body {
	line-height: 1;
}

ol,
ul {
	list-style: none;
}

blockquote,
q {
	quotes: none;
}

blockquote::before,
blockquote::after,
q::before,
q::after {
	content: "";
	content: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

[hidden] {
	display: none !important;
}

:root {
	--ratio: 1.125;
	--fz-ratio-power--four: calc(1rem * var(--ratio) * var(--ratio) * var(--ratio) * var(--ratio));
	--fz-ratio-power--three: calc(1rem * var(--ratio) * var(--ratio) * var(--ratio));
	--fz-ratio-power--two: calc(1rem * var(--ratio) * var(--ratio));
	--fz-ratio-power--one: calc(1rem * var(--ratio));
	--fz-ratio-power--minus-one: calc(1rem / var(--ratio));
	--fz-ratio-power--minus-two: calc((1rem / var(--ratio)) / var(--ratio));
	--sp-ratio-power--four: calc(var(--fz-ratio-power--four) * 1.5);
	--sp-ratio-power--three: calc(var(--fz-ratio-power--three) * 1.5);
	--sp-ratio-power--two: calc(var(--fz-ratio-power--two) * 1.5);
	--sp-ratio-power--one: calc(var(--fz-ratio-power--one) * 1.5);
}

html[data-focus-source="key"] * {
	will-change: outline-offset;
	transition-property: outline-offset;
	transition-duration: .14s;
	transition-timing-function: ease-out;
	outline-offset: 0;
}

html[data-focus-source="key"] *:focus {
	z-index: 100;
	outline-offset: 4px;
	outline: 2px dashed #ff6c3d;
	overflow: visible;
}

html[data-focus-source=""] *:focus,
html[data-focus-source="pointer"] *:focus,
html[data-focus-source="script"] *:focus {
	outline: none;
}

*,
*::before,
*::after {
	box-sizing: border-box;
}

html {
	scroll-behavior: smooth;
}

body {
	will-change: unset;
	transform: none;
	filter: none;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	min-height: 100vh;
	width: 100%;
}

.modal_is_visible body {
	overflow: hidden;
	max-height: 100vh;
}

.main_content {
	flex: 1 0 auto;
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 100%;
	background-color: #f2f2f3;
}

img {
	display: block;
	height: auto;
	width: auto;
	max-width: 100%;
	vertical-align: bottom;
}

img[height][width] {
	height: auto;
}

img[width] {
	width: auto;
}

img[src$=".svg"] {
	height: auto;
	width: 100%;
	max-width: none;
}

svg {
	overflow: visible;
	height: 100%;
	max-height: 100%;
	width: 100%;
	max-width: 100%;
}

button {
	cursor: pointer;
	border: none;
	background-color: transparent;
	padding: 0;
	font: inherit;
	color: inherit;
}

:root {
	font-size: 13px;
}

html {
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	word-break: break-word;
}

body,
select,
input,
textarea {
	font-family: "tt_commons__book",sans-serif;
	font-weight: normal;
	line-height: 1.618;
	font-size: inherit;
	letter-spacing: 0;
	color: #3c494e;
}

h1,
h2,
h3,
h4 {
	display: block;
	color: #1f2223;
}

h1 a,
h2 a,
h3 a,
h4 a,
h1 a:visited,
h2 a:visited,
h3 a:visited,
h4 a:visited,
h1 a:visited:visited,
h2 a:visited:visited,
h3 a:visited:visited,
h4 a:visited:visited {
	will-change: color;
	transition-property: color;
	transition-duration: .14s;
	transition-timing-function: ease-out;
	color: #16b1a4;
}

h1 a:active,
h2 a:active,
h3 a:active,
h4 a:active,
h1 a:visited:active,
h2 a:visited:active,
h3 a:visited:active,
h4 a:visited:active,
h1 a:visited:visited:active,
h2 a:visited:visited:active,
h3 a:visited:visited:active,
h4 a:visited:visited:active,
h1 a:focus,
h2 a:focus,
h3 a:focus,
h4 a:focus,
h1 a:visited:focus,
h2 a:visited:focus,
h3 a:visited:focus,
h4 a:visited:focus,
h1 a:visited:visited:focus,
h2 a:visited:visited:focus,
h3 a:visited:visited:focus,
h4 a:visited:visited:focus,
h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h1 a:visited:hover,
h2 a:visited:hover,
h3 a:visited:hover,
h4 a:visited:hover,
h1 a:visited:visited:hover,
h2 a:visited:visited:hover,
h3 a:visited:visited:hover,
h4 a:visited:visited:hover {
	color: #098b80;
}

h1 {
	line-height: 1.5;
	font-family: "tt_commons__bold",sans-serif;
	font-weight: normal;
	max-width: 37ch;
	letter-spacing: 0;
	font-size: 1.601806640625rem;
	font-size: var(--fz-ratio-power--four);
}

* + h1 {
	margin-top: 2.4027099609375rem;
	margin-top: var(--sp-ratio-power--four);
}

h2 {
	line-height: 1.5;
	font-family: "tt_commons__bold",sans-serif;
	font-weight: normal;
	max-width: 44.4ch;
	letter-spacing: 0;
	font-size: 1.423828125rem;
	font-size: var(--fz-ratio-power--three);
}

* + h2 {
	margin-top: 2.1357421875rem;
	margin-top: var(--sp-ratio-power--three);
}

h3 {
	line-height: 1.5;
	font-family: "tt_commons__bold",sans-serif;
	font-weight: normal;
	max-width: 51.8ch;
	letter-spacing: 0;
	font-size: 1.265625rem;
	font-size: var(--fz-ratio-power--two);
}

* + h3 {
	margin-top: 1.8984375rem;
	margin-top: var(--sp-ratio-power--two);
}

h4 {
	line-height: 1.5;
	font-family: "tt_commons__bold",sans-serif;
	font-weight: normal;
	max-width: 59.2ch;
	letter-spacing: 0;
	font-size: 1.125rem;
	font-size: var(--fz-ratio-power--one);
}

* + h4 {
	margin-top: 1.6875rem;
	margin-top: var(--sp-ratio-power--one);
}

p {
	width: auto;
	max-width: 100%;
	font-family: "tt_commons__book",sans-serif;
	font-size: 1rem;
	line-height: 1.618;
	letter-spacing: 0;
	color: #3c494e;
}

* + p {
	margin-top: 1.618rem;
}

h1 + p,
h2 + p,
h3 + p {
	margin-top: .5393333333333333rem;
}

h4 + p {
	margin-top: .809rem;
}

.reading_content p,
p.reading_content {
	max-width: 74ch;
}

.reading_content p > a,
p.reading_content > a,
.reading_content p > a:visited,
p.reading_content > a:visited,
.reading_content p > a:visited:visited,
p.reading_content > a:visited:visited {
	will-change: color;
	transition-property: color;
	transition-duration: .14s;
	transition-timing-function: ease-out;
	color: #16b1a4;
}

.reading_content p > a:active,
p.reading_content > a:active,
.reading_content p > a:visited:active,
p.reading_content > a:visited:active,
.reading_content p > a:visited:visited:active,
p.reading_content > a:visited:visited:active,
.reading_content p > a:focus,
p.reading_content > a:focus,
.reading_content p > a:visited:focus,
p.reading_content > a:visited:focus,
.reading_content p > a:visited:visited:focus,
p.reading_content > a:visited:visited:focus,
.reading_content p > a:hover,
p.reading_content > a:hover,
.reading_content p > a:visited:hover,
p.reading_content > a:visited:hover,
.reading_content p > a:visited:visited:hover,
p.reading_content > a:visited:visited:hover {
	color: #098b80;
}

a,
a:visited {
	will-change: background-color, box-shadow;
	transition-property: background-color, box-shadow;
	transition-duration: .14s;
	transition-timing-function: ease-out;
	cursor: pointer;
	text-decoration: none;
}

b,
strong {
	font-weight: normal;
	font-family: "tt_commons__medium",sans-serif;
	letter-spacing: 0;
}

i,
em {
	font-style: normal;
	font-family: "tt_commons__book_italic",sans-serif;
}

sup,
sub {
	font-size: .8888888888888888rem;
	font-size: var(--fz-ratio-power--minus-one);
}

sup {
	vertical-align: super;
}

sub {
	vertical-align: sub;
}

::-moz-selection {
	background-color: #3c494e;
	text-shadow: 0 0 .1em #1f2223;
	color: #fff;
}

::selection {
	background-color: #3c494e;
	text-shadow: 0 0 .1em #1f2223;
	color: #fff;
}

.reading_content {
	width: 100%;
}

.reading_content ul,
.reading_content ol,
.reading_content dl {
	display: block;
	width: 100%;
	max-width: 74ch;
	padding-left: 3ch;
}

.reading_content * + ul,
.reading_content * + ol,
.reading_content * + dl {
	margin-top: 1.077588rem;
}

.reading_content ul {
	list-style-type: disc;
}

.reading_content ul ul {
	list-style-type: circle;
}

.reading_content ul ul ul {
	list-style-type: square;
}

.reading_content ol {
	list-style-type: decimal;
}

.reading_content ol ol {
	list-style-type: lower-alpha;
}

.reading_content ol ol ol {
	list-style-type: lower-roman;
}

.reading_content li {
	width: 74ch;
	max-width: 100%;
}

.reading_content li,
.reading_content li:visited {
	will-change: color;
	transition-property: color;
	transition-duration: .14s;
	transition-timing-function: ease-out;
	color: #16b1a4;
}

.reading_content li:active,
.reading_content li:visited:active,
.reading_content li:focus,
.reading_content li:visited:focus,
.reading_content li:hover,
.reading_content li:visited:hover {
	color: #098b80;
}

.reading_content li ul:first-child,
.reading_content li ol:first-child {
	margin-top: .809rem;
}

.reading_content * + li {
	margin-top: .809rem;
}

.global_footer {
	width: 100%;
	max-width: 100%;
	border-top: 2px solid #3c494e;
}

.global_footer__inner {
	display: flex;
	flex-direction: column;
	align-items: stretch;
	justify-content: space-between;
	margin-right: auto;
	margin-left: auto;
	width: 92%;
	max-width: 1520px;
}

.global_footer__navigation,
.global_footer__navigation__list {
	width: 100%;
	max-width: 100%;
}

.global_footer__navigation__list {
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	gap: 0 .809rem;
	padding: .4045rem .4045rem .4045rem 0;
}

.global_footer__navigation__item {
	flex: 0 1 auto;
}

.global_footer__navigation__anchor {
	will-change: color;
	transition-property: color;
	transition-duration: .14s;
	transition-timing-function: ease-out;
	font-size: 1.125rem;
	font-size: var(--fz-ratio-power--one);
	font-family: "tt_commons__medium",sans-serif;
	color: #16b1a4;
}

.global_footer__navigation__anchor:active,
.global_footer__navigation__anchor:focus,
.global_footer__navigation__anchor:hover {
	color: #098b80;
}

.global_footer__sponsors {
	position: relative;
	z-index: auto;
	border-top: 2px solid #3c494e;
}

.global_footer__sponsors::before {
	content: "";
	position: absolute;
	z-index: auto;
	top: -2px;
	right: -50%;
	height: 2px;
	width: 9999px;
	background-color: #3c494e;
}

.global_footer__sponsors,
.global_footer__sponsors__list {
	width: 100%;
	max-width: 100%;
}

.global_footer__sponsors__list {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	gap: .809rem;
	padding: .4045rem .4045rem .4045rem 0;
}

.global_footer__sponsors__item {
	flex: 0 1 auto;
}

.global_footer__sponsors__anchor {
	display: flex;
	height: 100%;
	overflow: hidden;
}

.global_footer__sponsors__anchor svg {
	max-height: 3rem;
	max-width: 7rem;
}

.global_footer__logo_and_legal {
	position: relative;
	z-index: auto;
}

.global_footer__logo {
	border-top: 2px solid #3c494e;
	padding: .809rem 0 .5393333333333333rem;
}

.global_footer__logo__anchor {
	display: flex;
}

.global_footer__logo__image {
	max-width: 13rem;
}

.global_footer__legal {
	position: relative;
	z-index: auto;
	border-top: 2px solid #3c494e;
	padding: .20225rem 0;
	font-size: .8888888888888888rem;
	font-size: var(--fz-ratio-power--minus-one);
}

.global_footer__legal::before {
	content: "";
	position: absolute;
	z-index: auto;
	top: -2px;
	left: -50%;
	height: 2px;
	width: 9999px;
	background-color: #3c494e;
}

.global_header {
	width: 100%;
	max-width: 100%;
	border-bottom: 1px solid $cl--interface--ligh;
}

.global_header__inner {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: space-between;
	gap: 1.618rem;
	margin-right: auto;
	margin-left: auto;
	width: 92%;
	max-width: 1520px;
	padding-top: .5em;
}

#snackbar {
	visibility: hidden;
	min-width: 250px;
	margin-left: -125px;
	background-color: #333;
	color: #fff;
	text-align: center;
	border-radius: .5em;
	padding: 16px;
	position: fixed;
	z-index: 1;
	left: 50%;
	bottom: 30px;
}

#snackbar.show {
	visibility: visible !important;
	animation: fadein .5s,fadeout .5s 2.5s;
}

.graph {
	position: relative;
	z-index: auto;
	margin-top: .809rem;
	margin-right: auto;
	margin-left: auto;
	width: 92%;
	max-width: 1520px;
	border-radius: 8px;
	border: 1px solid #b4bdc0;
	background-color: #eaebeb;
	padding: .809rem;
}

.graph__logo__anchor {
	left: -.809rem;
	display: inline-block;
}

.graph__logo__image {
	width: 12rem;
}

.graph__logo__beta_signifier {
	display: inline-block;
	border: 1px solid #59afa4;
	border-radius: 8px;
	background-color: #fff;
	padding: .1rem .4045rem 0;
	text-transform: uppercase;
	font-size: .8888888888888888rem;
	font-size: var(--fz-ratio-power--minus-one);
	letter-spacing: .02em;
	color: #59afa4;
}

.graph__graph_container {
	width: 100%;
	max-width: 100%;
	overflow-x: auto;
}

.graph__graph_container__inner {
	display: flex;
	justify-content: center;
	width: 100%;
	max-width: 100%;
	min-width: 760px;
	padding: 2px;
}

.graph__filters {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: stretch;
	justify-content: space-between;
	gap: 1.077588rem;
	margin-top: 1.618rem;
	border-top: 2px solid #b4bdc0;
	padding-top: 1.618rem;
}

.graph__filters__search,
.graph__filters__node_type,
.graph__filters__selected_nodes {
	box-shadow: 0 1px 4px hsla(197,9%,44%,0.040000000000000036),0 1px 2px hsla(197,9%,44%,0.19999999999999996);
	border-radius: 8px;
	background-color: #fff;
	padding: .5393333333333333rem .809rem .809rem;
	font-size: .8888888888888888rem;
	font-size: var(--fz-ratio-power--minus-one);
}

.graph__filters__search__label,
.graph__filters__node_type__label,
.graph__filters__selected_nodes__label {
	display: inline-block;
	font-family: "tt_commons__medium",sans-serif;
}

.graph__filters__search__label::after,
.graph__filters__node_type__label::after,
.graph__filters__selected_nodes__label::after {
	content: ":";
}

.graph__filters__search svg,
.graph__filters__node_type svg,
.graph__filters__selected_nodes svg {
	height: .809rem;
	width: .809rem;
}

.graph__filters__node_type__list,
.graph__filters__selected_nodes__list {
	display: flex;
	flex-wrap: wrap;
	gap: .5393333333333333rem;
	flex-direction: row;
	align-items: center;
}

* + .graph__filters__node_type__list,
* + .graph__filters__selected_nodes__list {
	margin-top: .26966666666666667rem;
}

.graph__filters__node_type__item,
.graph__filters__selected_nodes__item {
	flex: 0 0 auto;
}

.graph__filters__node_type__button--positive_properties,
.graph__filters__selected_nodes__button--positive_properties {
	will-change: background-color;
	transition-property: background-color;
	transition-duration: .14s;
	transition-timing-function: ease-out;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: .26966666666666667rem;
	border: 1px solid #c4bdfa;
	border-radius: 8px;
	background-color: #f6f5ff;
	padding: .13483333333333333rem .4045rem .1618rem;
	color: #1f2223;
}

.graph__filters__node_type__button--positive_properties:active,
.graph__filters__selected_nodes__button--positive_properties:active,
.graph__filters__node_type__button--positive_properties:focus,
.graph__filters__selected_nodes__button--positive_properties:focus,
.graph__filters__node_type__button--positive_properties:hover,
.graph__filters__selected_nodes__button--positive_properties:hover {
	background-color: hsla(247,105%,95%,1);
}

.graph__filters__node_type__button--positive_properties svg,
.graph__filters__selected_nodes__button--positive_properties svg {
	height: .809rem;
	width: .809rem;
}

.graph__filters__node_type__button--harms,
.graph__filters__selected_nodes__button--harms {
	will-change: background-color;
	transition-property: background-color;
	transition-duration: .14s;
	transition-timing-function: ease-out;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: .26966666666666667rem;
	border: 1px solid #ffe2b3;
	border-radius: 8px;
	background-color: #fff5e5;
	padding: .13483333333333333rem .4045rem .1618rem;
	color: #1f2223;
}

.graph__filters__node_type__button--harms:active,
.graph__filters__selected_nodes__button--harms:active,
.graph__filters__node_type__button--harms:focus,
.graph__filters__selected_nodes__button--harms:focus,
.graph__filters__node_type__button--harms:hover,
.graph__filters__selected_nodes__button--harms:hover {
	background-color: hsla(37,105%,92%,1);
}

.graph__filters__node_type__button--harms svg,
.graph__filters__selected_nodes__button--harms svg {
	height: .809rem;
	width: .809rem;
}

.graph__filters__node_type__button--tags,
.graph__filters__selected_nodes__button--tags {
	will-change: background-color;
	transition-property: background-color;
	transition-duration: .14s;
	transition-timing-function: ease-out;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: .26966666666666667rem;
	border: 1px solid #00a3f5;
	border-radius: 8px;
	background-color: #e5f6ff;
	padding: .13483333333333333rem .4045rem .1618rem;
	color: #1f2223;
}

.graph__filters__node_type__button--tags:active,
.graph__filters__selected_nodes__button--tags:active,
.graph__filters__node_type__button--tags:focus,
.graph__filters__selected_nodes__button--tags:focus,
.graph__filters__node_type__button--tags:hover,
.graph__filters__selected_nodes__button--tags:hover {
	background-color: hsla(200,105%,92%,1);
}

.graph__filters__node_type__button--tags svg,
.graph__filters__selected_nodes__button--tags svg {
	height: .809rem;
	width: .809rem;
}

.graph__filters__node_type__button--resource_type,
.graph__filters__selected_nodes__button--resource_type {
	will-change: background-color;
	transition-property: background-color;
	transition-duration: .14s;
	transition-timing-function: ease-out;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: .26966666666666667rem;
	border: 1px solid #4afc9d;
	border-radius: 8px;
	background-color: #dbffec;
	padding: .13483333333333333rem .4045rem .1618rem;
	color: #1f2223;
}

.graph__filters__node_type__button--resource_type:active,
.graph__filters__selected_nodes__button--resource_type:active,
.graph__filters__node_type__button--resource_type:focus,
.graph__filters__selected_nodes__button--resource_type:focus,
.graph__filters__node_type__button--resource_type:hover,
.graph__filters__selected_nodes__button--resource_type:hover {
	background-color: hsla(148,105%,90%,1);
}

.graph__filters__node_type__button--resource_type svg,
.graph__filters__selected_nodes__button--resource_type svg {
	height: .809rem;
	width: .809rem;
}

.graph__filters__node_type__button--add_to_query,
.graph__filters__selected_nodes__button--add_to_query {
	will-change: background-color;
	transition-property: background-color;
	transition-duration: .14s;
	transition-timing-function: ease-out;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: .26966666666666667rem;
	border: 1px solid #16b1a4;
	border-radius: 8px;
	background-color: #fff;
	padding: .13483333333333333rem .4045rem .1618rem;
	color: #16b1a4;
}

.graph__filters__node_type__button--add_to_query:active,
.graph__filters__selected_nodes__button--add_to_query:active,
.graph__filters__node_type__button--add_to_query:focus,
.graph__filters__selected_nodes__button--add_to_query:focus,
.graph__filters__node_type__button--add_to_query:hover,
.graph__filters__selected_nodes__button--add_to_query:hover {
	background-color: hsla(0,5%,97%,1);
}

.graph__filters__node_type__button--add_to_query svg,
.graph__filters__selected_nodes__button--add_to_query svg {
	height: .809rem;
	width: .809rem;
}

.graph__filters__node_type__button--clear_query,
.graph__filters__selected_nodes__button--clear_query {
	will-change: background-color;
	transition-property: background-color;
	transition-duration: .14s;
	transition-timing-function: ease-out;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: .26966666666666667rem;
	border: 1px solid #e51c3e;
	border-radius: 8px;
	background-color: #fff;
	padding: .13483333333333333rem .4045rem .1618rem;
	color: #e51c3e;
}

.graph__filters__node_type__button--clear_query:active,
.graph__filters__selected_nodes__button--clear_query:active,
.graph__filters__node_type__button--clear_query:focus,
.graph__filters__selected_nodes__button--clear_query:focus,
.graph__filters__node_type__button--clear_query:hover,
.graph__filters__selected_nodes__button--clear_query:hover {
	background-color: hsla(0,5%,97%,1);
}

.graph__filters__node_type__button--clear_query svg,
.graph__filters__selected_nodes__button--clear_query svg {
	height: .809rem;
	width: .809rem;
}

.graph__filters__node_type__button--replace_query,
.graph__filters__selected_nodes__button--replace_query {
	will-change: background-color;
	transition-property: background-color;
	transition-duration: .14s;
	transition-timing-function: ease-out;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: .26966666666666667rem;
	border: 1px solid #ff6c3d;
	border-radius: 8px;
	background-color: #fff;
	padding: .13483333333333333rem .4045rem .1618rem;
	color: #ff6c3d;
}

.graph__filters__node_type__button--replace_query:active,
.graph__filters__selected_nodes__button--replace_query:active,
.graph__filters__node_type__button--replace_query:focus,
.graph__filters__selected_nodes__button--replace_query:focus,
.graph__filters__node_type__button--replace_query:hover,
.graph__filters__selected_nodes__button--replace_query:hover {
	background-color: hsla(0,5%,97%,1);
}

.graph__filters__node_type__button--replace_query svg,
.graph__filters__selected_nodes__button--replace_query svg {
	height: .809rem;
	width: .809rem;
}

.graph__filters__search {
	flex: 1 1 auto;
}

.graph__filters__search .select2-container {
	flex: 1 1 auto;
	width: auto !important;
}

.graph__filters__search .select2-container .select2-search--inline .select2-search__field {
	margin-top: 0;
	vertical-align: text-top;
	font-family: "tt_commons__book",sans-serif;
}

.graph__filters__search .select2-container .select2-search--inline .select2-search__field::-webkit-input-placeholder {
	color: #66757a;
}

.graph__filters__search .select2-container .select2-search--inline .select2-search__field:-moz-placeholder {
	color: #66757a;
}

.graph__filters__search .select2-container .select2-search--inline .select2-search__field::-moz-placeholder {
	color: #66757a;
}

.graph__filters__search .select2-container .select2-search--inline .select2-search__field:-ms-input-placeholder {
	color: #66757a;
}

.graph__filters__search .select2-container--default .select2-selection--multiple {
	border: 1px solid #b4bdc0;
	border-radius: 8px;
}

.graph__filters__search .select2-container--default .select2-selection--multiple .select2-selection__choice {
	background-color: #fff;
}

.graph__filters__search .select2-container--default .select2-selection--multiple .select2-selection__choice__display {
	display: inline-block;
	transform: translateY(-.05em);
	font-size: .8888888888888888rem;
	font-size: var(--fz-ratio-power--minus-one);
}

.graph__filters__search .select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
	will-change: background-color;
	transition-property: background-color;
	transition-duration: .14s;
	transition-timing-function: ease-out;
	color: #e51c3e;
}

.graph__filters__search .select2-container--default .select2-selection--multiple .select2-selection__choice__remove:active,
.graph__filters__search .select2-container--default .select2-selection--multiple .select2-selection__choice__remove:focus,
.graph__filters__search .select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover {
	background-color: hsla(349.9,79%,50%,0.09999999999999998);
}

.graph__filters__search__fields {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: .5393333333333333rem;
}

* + .graph__filters__search__fields {
	margin-top: .26966666666666667rem;
}

.graph__filters__search__input {
	flex: 1 1 auto;
	border: 1px solid #b4bdc0;
	border-radius: 8px;
	padding: .13483333333333333rem .4045rem .1618rem;
}

.graph__filters__search__button {
	flex: 0 0 auto;
}

.graph__filters__search__button--clear_query {
	will-change: background-color;
	transition-property: background-color;
	transition-duration: .14s;
	transition-timing-function: ease-out;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: .26966666666666667rem;
	border: 1px solid #e51c3e;
	border-radius: 8px;
	background-color: #fff;
	padding: .13483333333333333rem .4045rem .1618rem;
	color: #e51c3e;
}

.graph__filters__search__button--clear_query:active,
.graph__filters__search__button--clear_query:focus,
.graph__filters__search__button--clear_query:hover {
	background-color: hsla(0,5%,97%,1);
}

.graph__filters__search__button--clear_query svg {
	height: .809rem;
	width: .809rem;
}

.graph__filters__search__button--submit {
	will-change: background-color;
	transition-property: background-color;
	transition-duration: .14s;
	transition-timing-function: ease-out;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: .26966666666666667rem;
	border: 1px solid #16b1a4;
	border-radius: 8px;
	background-color: #fff;
	padding: .13483333333333333rem .4045rem .1618rem;
	color: #16b1a4;
}

.graph__filters__search__button--submit:active,
.graph__filters__search__button--submit:focus,
.graph__filters__search__button--submit:hover {
	background-color: hsla(0,5%,97%,1);
}

.graph__filters__search__button--submit svg {
	height: .809rem;
	width: .809rem;
}

.graph__filters__node_type {
	flex: 0 1 13rem;
}

.graph__filters__node_type__list {
	flex-wrap: wrap;
}

.graph__filters__selected_nodes {
	flex: 1 1 auto;
}

.harm {
	width: 100%;
	max-width: 100%;
}

.harm__heading_and_description_and_related_positive_properties {
	display: flex;
	flex-direction: column;
	gap: .809rem;
}

.harm__heading_and_description,
.harm__related_positive_properties {
	flex: 0 0 100%;
}

.harm__heading__label {
	display: block;
	font-size: .8888888888888888rem;
	font-size: var(--fz-ratio-power--minus-one);
	font-family: "tt_commons__medium",sans-serif;
}

.harm__heading__label::after {
	content: ":";
}

* + .harm__heading__text {
	margin-top: 0;
}

.harm__description p {
	width: auto;
	max-width: $llch;
}

.harm__related_positive_properties__heading::after {
	content: ":";
}

.harm__related_positive_properties__list {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	gap: .5393333333333333rem;
	width: 100%;
	max-width: 100%;
}

* + .harm__related_positive_properties__list {
	margin-top: .5393333333333333rem;
}

.harm__related_positive_properties__button {
	will-change: background-color;
	transition-property: background-color;
	transition-duration: .14s;
	transition-timing-function: ease-out;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: .26966666666666667rem;
	border: 1px solid #afa5f8;
	border-radius: 8px;
	background-color: #c4bdfa;
	padding: .20225rem .809rem .26966666666666667rem;
	color: #1f2223;
	line-height: 1.5;
	font-family: "tt_commons__bold",sans-serif;
	font-weight: normal;
	max-width: 59.2ch;
	letter-spacing: 0;
	font-size: 1.125rem;
	font-size: var(--fz-ratio-power--one);
}

.harm__related_positive_properties__button:active,
.harm__related_positive_properties__button:focus,
.harm__related_positive_properties__button:hover {
	background-color: hsla(247,91%,83%,1);
}

.harm__related_positive_properties__button svg {
	height: .809rem;
	width: .809rem;
}

* + .harm__related_positive_properties__button {
	margin-top: 1.6875rem;
	margin-top: var(--sp-ratio-power--one);
}

.harm__challenges,
.harm__related_resources,
.harm__rephrain_internal {
	border-top: 4px solid #fff5e5;
	width: 100%;
	max-width: 100%;
}

* + .harm__challenges,
* + .harm__related_resources,
* + .harm__rephrain_internal {
	margin-top: 1.618rem;
}

* + .harm__challenges,
* + .harm__related_resources,
* + .harm__rephrain_internal {
	padding-top: 1.618rem;
}

.harm__challenges__heading::after,
.harm__related_resources__heading::after,
.harm__rephrain_internal__heading::after {
	content: ":";
}

.harm__challenges__description,
.harm__related_resources__description,
.harm__rephrain_internal__description {
	display: block;
	max-width: 74ch;
	font-family: "tt_commons__medium",sans-serif;
}

.harm__challenges {
	display: flex;
	flex-direction: column;
	-moz-column-count: 2;
	column-count: 2;
	gap: .809rem;
}

.harm__challenges__heading_and_description,
.harm__challenges__list {
	flex: 0 0 100%;
}

.harm__challenges__list {
	padding-left: 1.5ch;
	list-style-type: decimal;
}

.harm__challenges__item {
	max-width: 74ch;
}

* + .harm__challenges__item {
	margin-top: .809rem;
}

.harm__challenges__challenge {
	display: block;
}

.harm__challenges__anchor {
	display: inline-block;
}

.harm__challenges__anchor,
.harm__challenges__anchor:visited {
	will-change: color;
	transition-property: color;
	transition-duration: .14s;
	transition-timing-function: ease-out;
	color: #16b1a4;
}

.harm__challenges__anchor:active,
.harm__challenges__anchor:visited:active,
.harm__challenges__anchor:focus,
.harm__challenges__anchor:visited:focus,
.harm__challenges__anchor:hover,
.harm__challenges__anchor:visited:hover {
	color: #098b80;
}

.harm__rephrain_internal {
	width: 100%;
	max-width: 100%;
}

.harm__rephrain_internal__heading_and_description,
.harm__rephrain_internal__list {
	width: 100%;
	max-width: 100%;
}

.harm__rephrain_internal__list {
	display: grid;
	gap: 1.618rem;
}

* + .harm__rephrain_internal__list {
	margin-top: 1.618rem;
}

.harm__rephrain_internal__item {
	display: flex;
}

.harm__rephrain_internal__item__anchor {
	will-change: border-color, box-shadow;
	transition-property: border-color, box-shadow;
	transition-duration: .14s;
	transition-timing-function: ease-out;
	flex: 1 1 auto;
	display: block;
	box-shadow: 0 1px 4px hsla(197,6%,13%,0.06000000000000005),0 1px 2px hsla(197,6%,13%,0.020000000000000018),inset 1px 0 0 #a4f4ed;
	border-radius: 8px;
	border: 1px solid #a4f4ed;
	padding: .7090000000000001rem .809rem .809rem;
	background-color: #f6fefd;
}

.harm__rephrain_internal__item__anchor:hover,
.harm__rephrain_internal__item__anchor:focus {
	box-shadow: 0 6px 12px hsla(197,6%,13%,0.06000000000000005),0 5px 8px hsla(197,6%,13%,0.020000000000000018),inset 3px 0 0 #a4f4ed;
}

.harm__rephrain_internal__item__anchor:active .harm__rephrain_internal__item__link_signifier,
.harm__rephrain_internal__item__anchor:focus .harm__rephrain_internal__item__link_signifier,
.harm__rephrain_internal__item__anchor:hover .harm__rephrain_internal__item__link_signifier {
	color: #098b80;
}

.harm__rephrain_internal__item__heading {
	line-height: 1.5;
	font-family: "tt_commons__bold",sans-serif;
	font-weight: normal;
	max-width: 59.2ch;
	letter-spacing: 0;
	font-size: 1.125rem;
	font-size: var(--fz-ratio-power--one);
}

* + .harm__rephrain_internal__item__heading {
	margin-top: 1.6875rem;
	margin-top: var(--sp-ratio-power--one);
}

.harm__rephrain_internal__item__description {
	display: block;
}

.harm__rephrain_internal__item__description {
	color: #3c494e;
}

.harm__rephrain_internal__item__link_signifier {
	will-change: color;
	transition-property: color;
	transition-duration: .14s;
	transition-timing-function: ease-out;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: .4045rem;
	color: #16b1a4;
}

* + .harm__rephrain_internal__item__link_signifier {
	margin-top: .4045rem;
}

.harm__rephrain_internal__item__link_signifier__text {
	flex: 0 1 auto;
	display: block;
}

.harm__rephrain_internal__item__link_signifier__icon {
	flex: 0 0 auto;
	display: flex;
	height: .809rem;
	width: .809rem;
	fill: currentcolor;
}

.harm__related_resources__filters {
	width: 100%;
	max-width: 100%;
}

* + .harm__related_resources__filters {
	margin-top: .5393333333333333rem;
}

.harm__related_resources__filters__list {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	gap: .5393333333333333rem;
	width: 100%;
	max-width: 100%;
}

.harm__related_resources__filters__item {
	flex: 0 0 auto;
}

.harm__related_resources__filters__button {
	will-change: background-color;
	transition-property: background-color;
	transition-duration: .14s;
	transition-timing-function: ease-out;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: .26966666666666667rem;
	border: 1px solid #77fdb6;
	border-radius: 8px;
	background-color: #dbffec;
	padding: .1618rem .5393333333333333rem .20225rem;
	color: #1f2223;
}

.harm__related_resources__filters__button:active,
.harm__related_resources__filters__button:focus,
.harm__related_resources__filters__button:hover {
	background-color: hsla(148,105%,90%,1);
}

.harm__related_resources__filters__button svg {
	height: .809rem;
	width: .809rem;
}

.harm__related_resources__filters__button[data-selected="selected"] {
	will-change: background-color;
	transition-property: background-color;
	transition-duration: .14s;
	transition-timing-function: ease-out;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: .26966666666666667rem;
	border: 1px solid #4afc9d;
	border-radius: 8px;
	background-color: #77fdb6;
	padding: .1618rem .5393333333333333rem .20225rem;
	color: #1f2223;
	outline: #4afc9d solid 4px;
	outline-offset: 2px;
}

.harm__related_resources__filters__button[data-selected="selected"]:active,
.harm__related_resources__filters__button[data-selected="selected"]:focus,
.harm__related_resources__filters__button[data-selected="selected"]:hover {
	background-color: hsla(148,102%,70%,1);
}

.harm__related_resources__filters__button[data-selected="selected"] svg {
	height: .809rem;
	width: .809rem;
}

.harm__related_resources__filters__button[data-selected="disabled"],
.harm__related_resources__filters__button[data-selected="disabled_and_selected"] {
	will-change: background-color;
	transition-property: background-color;
	transition-duration: .14s;
	transition-timing-function: ease-out;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: .26966666666666667rem;
	border: 1px solid #eaebeb;
	border-radius: 8px;
	background-color: #f2f2f3;
	padding: .1618rem .5393333333333333rem .20225rem;
	color: #66757a;
	cursor: not-allowed;
}

.harm__related_resources__filters__button[data-selected="disabled"]:active,
.harm__related_resources__filters__button[data-selected="disabled_and_selected"]:active,
.harm__related_resources__filters__button[data-selected="disabled"]:focus,
.harm__related_resources__filters__button[data-selected="disabled_and_selected"]:focus,
.harm__related_resources__filters__button[data-selected="disabled"]:hover,
.harm__related_resources__filters__button[data-selected="disabled_and_selected"]:hover {
	background-color: hsla(197,9%,92%,1);
}

.harm__related_resources__filters__button[data-selected="disabled"] svg,
.harm__related_resources__filters__button[data-selected="disabled_and_selected"] svg {
	height: .809rem;
	width: .809rem;
}

.harm__related_resources__filters__search {
	max-width: 1140px;
	font-size: .8888888888888888rem;
	font-size: var(--fz-ratio-power--minus-one);
}

* + .harm__related_resources__filters__search {
	margin-top: 1.077588rem;
}

.harm__related_resources__filters__search__label {
	display: inline-block;
	font-family: "tt_commons__medium",sans-serif;
}

.harm__related_resources__filters__search__label::after {
	content: ":";
}

.harm__related_resources__filters__search svg {
	height: .809rem;
	width: .809rem;
}

.harm__related_resources__filters__search .select2-container {
	flex: 1 1 auto;
	width: auto !important;
}

.harm__related_resources__filters__search .select2-container .select2-search--inline .select2-search__field {
	margin-top: 0;
	height: 1rem;
	vertical-align: middle;
	font-family: "tt_commons__book",sans-serif;
}

.harm__related_resources__filters__search .select2-container--default .select2-selection--multiple {
	border: 1px solid #b4bdc0;
	border-radius: 8px;
}

.harm__related_resources__filters__search .select2-container--default .select2-selection--multiple .select2-selection__choice {
	background-color: #fff;
}

.harm__related_resources__filters__search .select2-container--default .select2-selection--multiple .select2-selection__choice__display {
	display: inline-block;
	transform: translateY(-.05em);
	font-size: .8888888888888888rem;
	font-size: var(--fz-ratio-power--minus-one);
}

.harm__related_resources__filters__search .select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
	will-change: background-color;
	transition-property: background-color;
	transition-duration: .14s;
	transition-timing-function: ease-out;
	color: #e51c3e;
}

.harm__related_resources__filters__search .select2-container--default .select2-selection--multiple .select2-selection__choice__remove:active,
.harm__related_resources__filters__search .select2-container--default .select2-selection--multiple .select2-selection__choice__remove:focus,
.harm__related_resources__filters__search .select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover {
	background-color: hsla(349.9,79%,50%,0.09999999999999998);
}

.harm__related_resources__filters__search__fields {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: .5393333333333333rem;
}

* + .harm__related_resources__filters__search__fields {
	margin-top: .26966666666666667rem;
}

.harm__related_resources__filters__search__input {
	flex: 1 1 auto;
	border: 1px solid #b4bdc0;
	border-radius: 8px;
	padding: .13483333333333333rem .4045rem .1618rem;
}

.harm__related_resources__filters__search__button {
	flex: 0 0 auto;
}

.harm__related_resources__filters__search__button--clear_query {
	will-change: background-color;
	transition-property: background-color;
	transition-duration: .14s;
	transition-timing-function: ease-out;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: .26966666666666667rem;
	border: 1px solid #e51c3e;
	border-radius: 8px;
	background-color: #fff;
	padding: .13483333333333333rem .4045rem .1618rem;
	color: #e51c3e;
}

.harm__related_resources__filters__search__button--clear_query:active,
.harm__related_resources__filters__search__button--clear_query:focus,
.harm__related_resources__filters__search__button--clear_query:hover {
	background-color: hsla(0,5%,97%,1);
}

.harm__related_resources__filters__search__button--clear_query svg {
	height: .809rem;
	width: .809rem;
}

.harm__related_resources__filters__search__button--submit {
	will-change: background-color;
	transition-property: background-color;
	transition-duration: .14s;
	transition-timing-function: ease-out;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: .26966666666666667rem;
	border: 1px solid #16b1a4;
	border-radius: 8px;
	background-color: #fff;
	padding: .13483333333333333rem .4045rem .1618rem;
	color: #16b1a4;
}

.harm__related_resources__filters__search__button--submit:active,
.harm__related_resources__filters__search__button--submit:focus,
.harm__related_resources__filters__search__button--submit:hover {
	background-color: hsla(0,5%,97%,1);
}

.harm__related_resources__filters__search__button--submit svg {
	height: .809rem;
	width: .809rem;
}

.harm__related_resources__table {
	font-size: .8888888888888888rem;
	font-size: var(--fz-ratio-power--minus-one);
}

.harm__related_resources__table__wrap {
	overflow-x: auto;
	width: 100%;
	max-width: 100%;
	border-top: 2px solid #f2f2f3;
	padding-top: .5393333333333333rem;
	padding-right: 1px;
}

* + .harm__related_resources__table__wrap {
	margin-top: 1.077588rem;
}

.harm__related_resources__table__resource_type {
	position: relative;
	z-index: auto;
	display: inline-block;
}

.harm__related_resources__table__resource_type__button {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: .4045rem;
}

.harm__related_resources__table__resource_type__button:active svg,
.harm__related_resources__table__resource_type__button:focus svg,
.harm__related_resources__table__resource_type__button:hover svg {
	fill: #098b80;
}

.harm__related_resources__table__resource_type__text {
	flex: 0 0 auto;
	text-decoration: underline;
	-webkit-text-decoration-skip-ink: all;
	text-decoration-skip-ink: all;
	-webkit-text-decoration-color: #77fdb6;
	text-decoration-color: #77fdb6;
}

.harm__related_resources__table__resource_type svg {
	flex: 0 0 auto;
	will-change: fill;
	transition-property: fill;
	transition-duration: .14s;
	transition-timing-function: ease-out;
	height: .809rem;
	width: .809rem;
}

.harm__related_resources__table__resource_type svg path {
	fill: #16b1a4;
}

.harm__related_resources__table__resource_type__information {
	position: absolute;
	z-index: auto;
	display: none;
	top: 50%;
	left: calc(100% + 0.809rem);
	transform: translateY(-50%);
	width: -moz-max-content;
	width: max-content;
	max-width: 20rem;
	box-shadow: 0 1px 4px hsla(197,9%,44%,0.040000000000000036),0 1px 2px hsla(197,9%,44%,0.19999999999999996);
	border-radius: 8px;
	background-color: #dbffec;
	padding: .4045rem;
	text-align: left;
	font-weight: "tt_commons__medium",sans-serif;
}

.harm__related_resources__table__resource_type__information.harm__related_resources__table__resource_type__information--visible {
	display: block;
}

.harm__related_resources__table__resource_type__information::before {
	content: "";
	position: absolute;
	top: 50%;
	left: -1.077588rem;
	transform: translateY(-50%);
	height: 0;
	width: 0;
	border-width: .5393333333333333rem;
	border-style: solid;
	border-color: transparent #dbffec transparent transparent;
}

.harm__related_resources__table__resource_details,
.harm__related_resources__table__resource_details__heading {
	min-width: 5ch;
	max-width: 5ch;
	text-align: right;
}

.harm__related_resources__table__resource_details__button {
	will-change: background-color;
	transition-property: background-color;
	transition-duration: .14s;
	transition-timing-function: ease-out;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: .26966666666666667rem;
	border: 1px solid #16b1a4;
	border-radius: 8px;
	background-color: #fff;
	padding: .13483333333333333rem .4045rem .1618rem;
	color: #16b1a4;
	display: inline-flex;
	height: 1.618rem;
	width: 1.618rem;
}

.harm__related_resources__table__resource_details__button:active,
.harm__related_resources__table__resource_details__button:focus,
.harm__related_resources__table__resource_details__button:hover {
	background-color: hsla(0,5%,97%,1);
}

.harm__related_resources__table__resource_details__button svg {
	height: .809rem;
	width: .809rem;
}

.harm__related_resources__table__resource_details__button__text {
	flex: 0 0 auto;
}

.harm__related_resources__table__resource_details__button svg {
	flex: 0 0 auto;
	height: .809rem;
	width: .809rem;
	fill: currentcolor;
}

.harm__related_resources__table__resource_location {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	gap: .5393333333333333rem;
}

.harm__related_resources__table__resource_anchor,
.harm__related_resources__table__copy_anchor_button {
	flex: 0 0 auto;
	will-change: color;
	transition-property: color;
	transition-duration: .14s;
	transition-timing-function: ease-out;
	color: #16b1a4;
}

.harm__related_resources__table__resource_anchor:active,
.harm__related_resources__table__copy_anchor_button:active,
.harm__related_resources__table__resource_anchor:focus,
.harm__related_resources__table__copy_anchor_button:focus,
.harm__related_resources__table__resource_anchor:hover,
.harm__related_resources__table__copy_anchor_button:hover {
	color: #098b80;
}

.harm__related_resources__table__resource_anchor {
	flex: 1 1 auto;
	will-change: background-color;
	transition-property: background-color;
	transition-duration: .14s;
	transition-timing-function: ease-out;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: .26966666666666667rem;
	border: 1px solid #16b1a4;
	border-radius: 8px;
	background-color: #fff;
	padding: .13483333333333333rem .4045rem .1618rem;
	color: #16b1a4;
	justify-content: space-between;
}

.harm__related_resources__table__resource_anchor:active,
.harm__related_resources__table__resource_anchor:focus,
.harm__related_resources__table__resource_anchor:hover {
	background-color: hsla(0,5%,97%,1);
}

.harm__related_resources__table__resource_anchor svg {
	height: .809rem;
	width: .809rem;
}

.harm__related_resources__table__resource_anchor__text {
	flex: 0 1 auto;
}

.harm__related_resources__table__resource_anchor svg {
	flex: 0 0 auto;
	height: .809rem;
	width: .809rem;
	fill: currentcolor;
}

.harm__related_resources__table__copy_anchor_button {
	display: flex;
}

.harm__related_resources__table__copy_anchor_button svg {
	height: .809rem;
	width: .809rem;
	fill: currentcolor;
}

.harm__related_resources__dialog {
	border-radius: 8px;
	width: 92vw;
	max-width: 1140px;
	border: none;
	box-shadow: 0 .20225rem .809rem hsla(197,9%,44%,0.4),0 .4045rem .4045rem hsla(197,13%,27%,0.4);
	padding: 1.618rem;
}

.harm__related_resources__dialog [edit-mode="none"] .edit-form,
.harm__related_resources__dialog [edit-mode="none"] .thanks {
	display: none;
}

.harm__related_resources__dialog [edit-mode="editing"] #propose-changes,
.harm__related_resources__dialog [edit-mode="editing"] .thanks {
	display: none;
}

.harm__related_resources__dialog [edit-mode="complete"] .edit-form {
	display: none;
}

.harm__related_resources__dialog #propose-changes {
	position: absolute;
	top: 29px;
	right: 90px;
	color: #098b80;
}

.harm__related_resources__dialog .thanks {
	color: #098b80;
	text-align: right;
	margin-bottom: 1em;
	margin-right: 1em;
}

.harm__related_resources__dialog #reasoning {
	margin-left: 2ch;
}

.harm__related_resources__dialog .edit-form {
	width: 100%;
	padding: 1em;
}

.harm__related_resources__dialog .edit-form.buttons {
	text-align: right;
}

.harm__related_resources__dialog .edit-form.buttons button {
	display: inline-flex;
	flex-direction: row;
	align-items: center;
	gap: .26966666666666667rem;
	border: 1px solid #e51c3e;
	border-radius: 8px;
	background-color: #fff;
	padding: .13483333333333333rem .4045rem .1618rem;
	color: #e51c3e;
	margin-left: .3em;
}

.harm__related_resources__dialog .edit-form.buttons button#save {
	color: #16b1a4;
	border-color: #16b1a4;
}

.harm__related_resources__dialog input,
.harm__related_resources__dialog textarea {
	width: 100%;
	padding-left: .25em;
}

.harm__related_resources__dialog::backdrop {
	background-color: rgba(102,117,122,0.7);
	-webkit-backdrop-filter: blur(2px);
	backdrop-filter: blur(2px);
}

.harm__related_resources__dialog__heading_and_close_form {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: space-between;
	width: 100%;
	max-width: 100%;
}

.harm__related_resources__dialog__heading {
	flex: 1 1 auto;
}

.harm__related_resources__dialog__close_form {
	flex: 0 0 auto;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
}

* + .harm__related_resources__dialog__close_form {
	margin-left: 1.618rem;
}

.harm__related_resources__dialog__close_button {
	will-change: color;
	transition-property: color;
	transition-duration: .14s;
	transition-timing-function: ease-out;
	display: flex;
	color: #16b1a4;
}

.harm__related_resources__dialog__close_button:active,
.harm__related_resources__dialog__close_button:focus,
.harm__related_resources__dialog__close_button:hover {
	color: #098b80;
}

.harm__related_resources__dialog__close_button svg {
	height: 1.618rem;
	width: 1.618rem;
	fill: currentcolor;
}

.harm__related_resources__dialog__list {
	display: grid;
	grid-template-columns: repeat(2,1fr);
	overflow: hidden;
	border-radius: 8px;
	border: 1px solid #f2f2f3;
}

* + .harm__related_resources__dialog__list {
	margin-top: 1.077588rem;
}

.harm__related_resources__dialog__item {
	padding: 1.078666666666667rem 1.077588rem .809rem;
}

.harm__related_resources__dialog__item:nth-child(even) {
	border-left: 1px solid #f2f2f3;
}

.harm__related_resources__dialog__item:not(:last-child) {
	position: relative;
	z-index: auto;
}

.harm__related_resources__dialog__item:not(:last-child)::after {
	content: "";
	position: absolute;
	z-index: auto;
	bottom: 0;
	left: 0;
	height: 1px;
	width: 200%;
	background-color: #f2f2f3;
}

.harm__related_resources__dialog__item.harm__related_resources__dialog__item--tag span:not(.none_value),
.harm__related_resources__dialog__item.harm__related_resources__dialog__item--harm span:not(.none_value) {
	display: inline-block;
	border-radius: 8px;
	font-size: .8888888888888888rem;
	font-size: var(--fz-ratio-power--minus-one);
	color: #1f2223;
	padding: .1618rem .4045rem;
	line-height: 1.3;
}

.harm__related_resources__dialog__item.harm__related_resources__dialog__item--tag span:not(.none_value):not(:last-child),
.harm__related_resources__dialog__item.harm__related_resources__dialog__item--harm span:not(.none_value):not(:last-child) {
	margin-right: .5393333333333333rem;
}

.harm__related_resources__dialog__item.harm__related_resources__dialog__item--harm span:not(.none_value) {
	border: 1px solid #ffce80;
	background-color: #fff5e5;
}

.harm__related_resources__dialog__item.harm__related_resources__dialog__item--tag {
	grid-column: span 2;
	border-left: none;
}

.harm__related_resources__dialog__item.harm__related_resources__dialog__item--tag span:not(.none_value) {
	border: 1px solid #00a3f5;
	background-color: #e5f6ff;
}

.harm__related_resources__dialog__term {
	font-size: .8888888888888888rem;
	font-size: var(--fz-ratio-power--minus-one);
	font-family: "tt_commons__medium",sans-serif;
	color: #1f2223;
}

.harm__related_resources__dialog__term::after {
	content: ":";
}

.harm__related_resources__dialog__description {
	margin-left: 2ch;
	color: #3c494e;
}

* + .harm__related_resources__dialog__description {
	margin-top: .26966666666666667rem;
}

.harm__related_resources__dialog__description a,
.harm__related_resources__dialog__description a:visited {
	will-change: color;
	transition-property: color;
	transition-duration: .14s;
	transition-timing-function: ease-out;
	color: #16b1a4;
}

.harm__related_resources__dialog__description a:active,
.harm__related_resources__dialog__description a:visited:active,
.harm__related_resources__dialog__description a:focus,
.harm__related_resources__dialog__description a:visited:focus,
.harm__related_resources__dialog__description a:hover,
.harm__related_resources__dialog__description a:visited:hover {
	color: #098b80;
}

.harm__related_resources__dialog__description .none_value {
	font-family: "tt_commons__book_italic",sans-serif;
	color: #66757a;
}

.harm__url_copy_dialog {
	border-radius: 8px;
	border: none;
	box-shadow: 0 .20225rem .809rem hsla(197,9%,44%,0.4),0 .4045rem .4045rem hsla(197,13%,27%,0.4);
	padding: 1.077588rem 1.618rem;
}

.harm__url_copy_dialog--hide {
	animation: modal--close 1.5s cubic-bezier(.075,.82,.165,1);
}

.harm__url_copy_dialog::backdrop {
	background-color: transparent;
}

login-avatar {
	display: grid;
	grid-template: "avatar username" min-content;
	align-items: center;
	gap: .5em;
}

login-avatar img {
	width: 1.5em;
	height: 1.5em;
	border-radius: 50%;
	border: 1px solid #ccc;
}

login-state {
	display: content;
	height: 2em;
	overflow: hidden;
}

login-state div {
	display: none;
}

login-state.loaded div {
	display: inline-block;
}

button.login-required {
	cursor: not-allowed;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: .26966666666666667rem;
	border-radius: 8px;
	background-color: #f2f2f3;
	padding: .1618rem .5393333333333333rem .20225rem;
	color: #1f2223;
	margin-bottom: 1em;
}

.page_not_found {
	margin-top: .809rem;
	margin-right: auto;
	margin-left: auto;
	width: 92%;
	max-width: 1520px;
	border-radius: 8px;
	border: 1px solid #c4bdfa;
	border-left-width: 1.077588rem;
	background-color: #fff;
	padding: .809rem;
}

.page_not_found__logo__anchor {
	display: inline-block;
}

.page_not_found__logo__image {
	width: 12rem;
}

.page_not_found__heading {
	border-top: 2px solid #f6f5ff;
	padding-top: 1.077588rem;
}

* + .page_not_found__heading {
	margin-top: 1.077588rem;
}

.page_not_found__message {
	display: block;
	font-size: 1.125rem;
	font-size: var(--fz-ratio-power--one);
}

* + .page_not_found__message {
	margin-top: .809rem;
}

.page_not_found__message a,
.page_not_found__message a:visited {
	will-change: color;
	transition-property: color;
	transition-duration: .14s;
	transition-timing-function: ease-out;
	color: #16b1a4;
}

.page_not_found__message a:active,
.page_not_found__message a:visited:active,
.page_not_found__message a:focus,
.page_not_found__message a:visited:focus,
.page_not_found__message a:hover,
.page_not_found__message a:visited:hover {
	color: #098b80;
}

.positive_property {
	position: relative;
	z-index: auto;
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 100%;
	padding-left: 1.077588rem;
}

.positive_property__label {
	position: absolute;
	z-index: auto;
	top: 0;
	bottom: 0;
	left: 0;
	width: 1.077588rem;
	border-right: 1px solid var(--colour-positive-property);
	background-color: var(--colour-positive-property);
	background-position: left top;
	background-repeat: repeat;
	background-size: 8rem;
}

.positive_property__label__text {
	position: absolute;
	z-index: auto;
	bottom: 1.077588rem;
	left: 1.027588rem;
	transform: rotate(-90deg);
	transform-origin: left bottom;
	text-transform: uppercase;
	height: 1.077588rem;
	white-space: nowrap;
	font-size: .7901234567901234rem;
	font-size: var(--fz-ratio-power--minus-two);
	font-family: "tt_commons__medium",sans-serif;
	text-shadow: 0 0 1px #fff,0 0 2px #fff,0 0 3px #fff,0 0 4px #fff;
}

.positive_property__introduction,
.positive_property__related_harms {
	flex: 0 0 100%;
}

.positive_property__introduction {
	display: flex;
	flex-direction: column;
}

.positive_property__heading_and_description {
	padding: .809rem;
}

.positive_property__heading__label {
	display: block;
	font-size: .8888888888888888rem;
	font-size: var(--fz-ratio-power--minus-one);
	font-family: "tt_commons__medium",sans-serif;
}

* + .positive_property__heading__label {
	margin-top: .809rem;
}

.positive_property__heading__label::after {
	content: ":";
}

* + .positive_property__heading__text {
	margin-top: 0;
}

.positive_property__graphic {
	background-color: var(--colour-positive-property);
	background-position: left top;
	background-repeat: repeat;
	background-size: 8rem;
	overflow: hidden;
	border-left: 1px solid var(--colour-positive-property);
}

.positive_property__related_harms {
	flex: 0 0 100%;
	border-top: 1px solid var(--colour-positive-property);
	padding: .5393333333333333rem .809rem .809rem;
}

.positive_property__related_harms__heading::after {
	content: ":";
}

.positive_property__related_harms__list {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	gap: .5393333333333333rem;
	width: 100%;
	max-width: 100%;
}

* + .positive_property__related_harms__list {
	margin-top: .5393333333333333rem;
}

.positive_property__related_harms__item {
	flex: 0 0 auto;
}

.positive_property__related_harms__button {
	will-change: background-color;
	transition-property: background-color;
	transition-duration: .14s;
	transition-timing-function: ease-out;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: .26966666666666667rem;
	border: 1px solid #ffce80;
	border-radius: 8px;
	background-color: #ffe2b3;
	padding: .20225rem .809rem .26966666666666667rem;
	color: #1f2223;
	line-height: 1.5;
	font-family: "tt_commons__bold",sans-serif;
	font-weight: normal;
	max-width: 59.2ch;
	letter-spacing: 0;
	font-size: 1.125rem;
	font-size: var(--fz-ratio-power--one);
}

.positive_property__related_harms__button:active,
.positive_property__related_harms__button:focus,
.positive_property__related_harms__button:hover {
	background-color: hsla(37,105%,82%,1);
}

.positive_property__related_harms__button svg {
	height: .809rem;
	width: .809rem;
}

* + .positive_property__related_harms__button {
	margin-top: 1.6875rem;
	margin-top: var(--sp-ratio-power--one);
}

propose-challenge-change a {
	color: #16b1a4;
}

propose-challenge-change a svg {
	height: .7em;
	width: .7em;
	display: inline-block;
	position: relative;
	top: 2px;
}

propose-harm-challenge-relationship button {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: .26966666666666667rem;
	border: 1px solid #77fdb6;
	border-radius: 8px;
	background-color: #dbffec;
	padding: .1618rem .5393333333333333rem .20225rem;
	color: #1f2223;
	margin-bottom: 1em;
	margin-top: 1em;
}

propose-harm-challenge-relationship button svg {
	height: 1em;
	width: auto;
}

.harm__related_resources__dialog select {
	width: 100%;
	border-radius: 3px;
	padding: 5px;
	padding-left: 8px;
	background-color: #fff;
	border: 1px solid #767676;
}

propose-harm-change button {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: .26966666666666667rem;
	border: 1px solid #77fdb6;
	border-radius: 8px;
	background-color: #dbffec;
	padding: .1618rem .5393333333333333rem .20225rem;
	color: #1f2223;
	margin-bottom: 1em;
}

propose-harm-change button svg {
	height: 1em;
	width: auto;
}

propose-harm-positive-relationship button {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: .26966666666666667rem;
	border: 1px solid #77fdb6;
	border-radius: 8px;
	background-color: #dbffec;
	padding: .32rem .5393333333333333rem .39225rem;
	color: #1f2223;
	margin-bottom: 1em;
}

propose-harm-positive-relationship button svg {
	height: 1em;
	width: auto;
}

propose-new-resource button {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: .26966666666666667rem;
	border: 1px solid #77fdb6;
	border-radius: 8px;
	background-color: #dbffec;
	padding: .1618rem .5393333333333333rem .20225rem;
	color: #1f2223;
	margin-bottom: 1em;
}

propose-new-resource button svg {
	height: 1em;
	width: auto;
}

#propose-resource-form {
	display: grid;
	gap: 1em;
	width: 200%;
}

#propose-resource-form input,
#propose-resource-form textarea {
	border: 1px solid #b4bdc0;
	border-radius: 8px;
	padding-left: 8px;
}

#propose-resource-form button {
	display: flex;
	flex-direction: column;
	align-items: center;
	align-content: center;
	text-align: center;
	gap: .26966666666666667rem;
	border: 1px solid #77fdb6;
	border-radius: 8px;
	background-color: #dbffec;
	padding: .2618rem .5393333333333333rem .25225rem;
	font-weight: 500;
	color: #1f2223;
}

propose-positive-change button {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: .26966666666666667rem;
	border: 1px solid #77fdb6;
	border-radius: 8px;
	background-color: #dbffec;
	padding: .1618rem .5393333333333333rem .20225rem;
	color: #1f2223;
	margin-bottom: 1em;
}

propose-positive-change button svg {
	height: 1em;
	width: auto;
}

propose-resource-change select {
	display: block;
	width: 100%;
	margin-top: .5em;
	padding: .25em;
}

.results {
	margin-top: 1.618rem;
	margin-bottom: 3.236rem;
	margin-right: auto;
	margin-left: auto;
	width: 92%;
	max-width: 1520px;
}

.results__list,
.results__item,
.results__harm {
	width: 100%;
	max-width: 100%;
}

.results__item {
	border-radius: 8px;
	background-color: #fff;
}

.results__item--harm {
	position: relative;
	z-index: auto;
	border: 1px solid #ffe2b3;
	border-left-width: 1.077588rem;
	padding: .809rem;
}

.results__item--harm::after {
	content: attr(data-heading);
	position: absolute;
	z-index: auto;
	bottom: 1.077588rem;
	left: -.1rem;
	transform: rotate(-90deg);
	transform-origin: left bottom;
	text-transform: uppercase;
	height: 1.077588rem;
	font-size: .7901234567901234rem;
	font-size: var(--fz-ratio-power--minus-two);
	font-family: "tt_commons__medium",sans-serif;
}

.results__item--positive_property {
	overflow: hidden;
	border: 1px solid var(--colour-positive-property);
	padding: 0;
}

table {
	display: table;
	table-layout: auto;
	border-collapse: separate;
	border-spacing: 0;
	width: 100%;
	max-width: 100%;
	background-color: #fff;
}

table thead,
table tbody {
	min-width: 100%;
}

table th,
table td {
	min-width: 20ch;
	max-width: 40ch;
	vertical-align: top;
}

table th:last-child,
table td:last-child {
	padding-right: 0;
}

table th {
	padding: 0 .809rem .4045rem 0;
	text-align: left;
	font-family: "tt_commons__medium",sans-serif;
	letter-spacing: 0;
	color: #1f2223;
}

table td {
	border-top: 1px solid #f2f2f3;
	padding: .4045rem .809rem .4045rem 0;
}

user-detail {
	display: grid;
	grid-template: "left middle right" min-content;
	grid-auto-columns: 1fr;
	gap: 1em;
}

user-detail.disabled {
	color: #aaa;
}

user-detail .avatar {
	display: grid;
	grid-template: "avatar username" min-content;
	grid-auto-columns: 1.5em 1fr;
	text-align: left;
	align-items: left;
	gap: .5em;
	padding: .5em;
}

user-detail .avatar img {
	width: 1.5em;
	height: 1.5em;
	border-radius: 50%;
	border: 1px solid #ccc;
}

user-detail .tags {
	padding: .5em;
}

user-detail .tags span {
	border: 1px solid #e51c3e;
	color: #e51c3e;
	padding: .4em;
	padding-top: 0;
	padding-bottom: 0;
	border-radius: .5em;
	margin-right: .25em;
}

user-detail .tags span.user {
	border: 1px solid #098b80;
	color: #098b80;
}

user-detail .tags span.disabled {
	border: 1px solid #999;
	color: #999;
}

user-detail .controls {
	padding: .5em;
	padding-right: 1em;
	text-align: right;
}

user-detail .controls button {
	min-width: 80px;
	display: inline-block;
}

user-list {
	display: block;
	z-index: auto;
	margin-top: 1.809rem;
	margin-right: auto;
	margin-left: auto;
	width: 92%;
	max-width: 1520px;
}

user-list h3 {
	margin-bottom: .5em;
}

user-list span.error {
	padding: .5em;
	border-radius: 4px;
	border: 1px solid #f00;
	color: #f00;
	background-color: rgba(255,0,0,0.09);
}

user-list user-detail:nth-child(even) {
	background-color: #fff;
}

@media (prefers-reduced-motion:reduce) {

html {
	scroll-behavior: auto;
}

}

@media screen and (min-width:480px) {

:root {
	font-size: calc(0.5vw + 10.6px);
}

}

@media only screen and (min-width:481px) {

.harm__rephrain_internal__list {
	grid-template-columns: repeat(2,1fr);
}

}

@media only screen and (min-width:681px) {

:root {
	--ratio: 1.1825;
}

h1 {
	font-size: 1.955260368789063rem;
	font-size: var(--fz-ratio-power--four);
}

* + h1 {
	margin-top: 2.932890553183595rem;
	margin-top: var(--sp-ratio-power--four);
}

h2 {
	font-size: 1.653497140625rem;
	font-size: var(--fz-ratio-power--three);
}

* + h2 {
	margin-top: 2.4802457109375rem;
	margin-top: var(--sp-ratio-power--three);
}

h3 {
	font-size: 1.39830625rem;
	font-size: var(--fz-ratio-power--two);
}

* + h3 {
	margin-top: 2.097459375000001rem;
	margin-top: var(--sp-ratio-power--two);
}

h4 {
	font-size: 1.1825rem;
	font-size: var(--fz-ratio-power--one);
}

* + h4 {
	margin-top: 1.77375rem;
	margin-top: var(--sp-ratio-power--one);
}

sup,
sub {
	font-size: .8456659619450316rem;
	font-size: var(--fz-ratio-power--minus-one);
}

.global_footer__navigation__anchor {
	font-size: 1.1825rem;
	font-size: var(--fz-ratio-power--one);
}

.global_footer__legal {
	font-size: .8456659619450316rem;
	font-size: var(--fz-ratio-power--minus-one);
}

.graph__logo__beta_signifier {
	font-size: .8456659619450316rem;
	font-size: var(--fz-ratio-power--minus-one);
}

.graph__filters__search,
.graph__filters__node_type,
.graph__filters__selected_nodes {
	font-size: .8456659619450316rem;
	font-size: var(--fz-ratio-power--minus-one);
}

.graph__filters__search .select2-container--default .select2-selection--multiple .select2-selection__choice__display {
	font-size: .8456659619450316rem;
	font-size: var(--fz-ratio-power--minus-one);
}

.harm__heading__label {
	font-size: .8456659619450316rem;
	font-size: var(--fz-ratio-power--minus-one);
}

.harm__related_positive_properties__button {
	font-size: 1.1825rem;
	font-size: var(--fz-ratio-power--one);
}

* + .harm__related_positive_properties__button {
	margin-top: 1.77375rem;
	margin-top: var(--sp-ratio-power--one);
}

.harm__rephrain_internal__item__heading {
	font-size: 1.1825rem;
	font-size: var(--fz-ratio-power--one);
}

* + .harm__rephrain_internal__item__heading {
	margin-top: 1.77375rem;
	margin-top: var(--sp-ratio-power--one);
}

.harm__related_resources__filters__search {
	font-size: .8456659619450316rem;
	font-size: var(--fz-ratio-power--minus-one);
}

.harm__related_resources__filters__search .select2-container--default .select2-selection--multiple .select2-selection__choice__display {
	font-size: .8456659619450316rem;
	font-size: var(--fz-ratio-power--minus-one);
}

.harm__related_resources__table {
	font-size: .8456659619450316rem;
	font-size: var(--fz-ratio-power--minus-one);
}

.harm__related_resources__dialog__item.harm__related_resources__dialog__item--tag span:not(.none_value),
.harm__related_resources__dialog__item.harm__related_resources__dialog__item--harm span:not(.none_value) {
	font-size: .8456659619450316rem;
	font-size: var(--fz-ratio-power--minus-one);
}

.harm__related_resources__dialog__term {
	font-size: .8456659619450316rem;
	font-size: var(--fz-ratio-power--minus-one);
}

.page_not_found__message {
	font-size: 1.1825rem;
	font-size: var(--fz-ratio-power--one);
}

.positive_property__label__text {
	font-size: .7151509191924157rem;
	font-size: var(--fz-ratio-power--minus-two);
}

.positive_property__heading__label {
	font-size: .8456659619450316rem;
	font-size: var(--fz-ratio-power--minus-one);
}

.positive_property__related_harms__button {
	font-size: 1.1825rem;
	font-size: var(--fz-ratio-power--one);
}

* + .positive_property__related_harms__button {
	margin-top: 1.77375rem;
	margin-top: var(--sp-ratio-power--one);
}

.results__item--harm::after {
	font-size: .7151509191924157rem;
	font-size: var(--fz-ratio-power--minus-two);
}

}

@media only screen and (min-width:881px) {

.harm__rephrain_internal__list {
	grid-template-columns: repeat(3,1fr);
}

.positive_property__introduction {
	flex-direction: row;
}

.positive_property__heading_and_description,
.positive_property__graphic {
	flex: 0 1 50%;
}

}

@media only screen and (min-width:1081px) {

.global_footer__inner {
	flex-direction: row;
}

.global_footer__navigation_and_sponsors {
	flex: 0 0 70%;
}

.global_footer__sponsors::before {
	right: 100%;
}

.global_footer__logo_and_legal {
	flex: 0 0 30%;
	border-left: 2px solid #3c494e;
}

.global_footer__logo {
	border-top: none;
}

.global_footer__logo {
	padding: .809rem .809rem .5393333333333333rem;
}

.global_footer__legal {
	padding: .20225rem .809rem;
}

.global_footer__legal::before {
	left: 100%;
}

.graph {
	margin-top: 1.618rem;
}

.graph {
	padding: 1.618rem;
}

.graph__filters__selected_nodes {
	flex: 0 1 auto;
}

.harm__heading_and_description_and_related_positive_properties {
	flex-direction: row;
}

.harm__heading_and_description_and_related_positive_properties {
	gap: 1.618rem;
}

.harm__heading_and_description,
.harm__related_positive_properties {
	flex: 0 1 50%;
}

.harm__related_positive_properties {
	padding-top: 1.618rem;
}

.harm__challenges {
	flex-direction: row;
}

.harm__challenges {
	gap: 1.618rem;
}

.harm__challenges__heading_and_description,
.harm__challenges__list {
	flex: 0 1 50%;
}

.harm__challenges__list {
	margin-top: 2.427rem;
}

.page_not_found {
	margin-top: 1.618rem;
}

.page_not_found {
	padding: 1.618rem;
}

.positive_property__label__text {
	left: 1.077588rem;
}

.positive_property__heading_and_description {
	padding: 1.618rem;
}

.positive_property__related_harms {
	padding: 1.077588rem 1.618rem 1.618rem;
}

.results__item--harm {
	padding: 1.618rem;
}

.results__item--harm::after {
	left: 0;
}

}

@media only screen and (min-width:1281px) {

:root {
	--ratio: 1.24;
}

h1 {
	font-size: 2.36421376rem;
	font-size: var(--fz-ratio-power--four);
}

* + h1 {
	margin-top: 3.54632064rem;
	margin-top: var(--sp-ratio-power--four);
}

h2 {
	font-size: 1.906624rem;
	font-size: var(--fz-ratio-power--three);
}

* + h2 {
	margin-top: 2.859936rem;
	margin-top: var(--sp-ratio-power--three);
}

h3 {
	font-size: 1.5376rem;
	font-size: var(--fz-ratio-power--two);
}

* + h3 {
	margin-top: 2.3064rem;
	margin-top: var(--sp-ratio-power--two);
}

h4 {
	font-size: 1.24rem;
	font-size: var(--fz-ratio-power--one);
}

* + h4 {
	margin-top: 1.86rem;
	margin-top: var(--sp-ratio-power--one);
}

sup,
sub {
	font-size: .8064516129032259rem;
	font-size: var(--fz-ratio-power--minus-one);
}

.global_footer__navigation__anchor {
	font-size: 1.24rem;
	font-size: var(--fz-ratio-power--one);
}

.global_footer__legal {
	font-size: .8064516129032259rem;
	font-size: var(--fz-ratio-power--minus-one);
}

.graph__logo__beta_signifier {
	font-size: .8064516129032259rem;
	font-size: var(--fz-ratio-power--minus-one);
}

.graph__filters__search,
.graph__filters__node_type,
.graph__filters__selected_nodes {
	font-size: .8064516129032259rem;
	font-size: var(--fz-ratio-power--minus-one);
}

.graph__filters__search .select2-container--default .select2-selection--multiple .select2-selection__choice__display {
	font-size: .8064516129032259rem;
	font-size: var(--fz-ratio-power--minus-one);
}

.harm__heading__label {
	font-size: .8064516129032259rem;
	font-size: var(--fz-ratio-power--minus-one);
}

.harm__related_positive_properties__button {
	font-size: 1.24rem;
	font-size: var(--fz-ratio-power--one);
}

* + .harm__related_positive_properties__button {
	margin-top: 1.86rem;
	margin-top: var(--sp-ratio-power--one);
}

.harm__rephrain_internal__item__heading {
	font-size: 1.24rem;
	font-size: var(--fz-ratio-power--one);
}

* + .harm__rephrain_internal__item__heading {
	margin-top: 1.86rem;
	margin-top: var(--sp-ratio-power--one);
}

.harm__related_resources__filters__search {
	font-size: .8064516129032259rem;
	font-size: var(--fz-ratio-power--minus-one);
}

.harm__related_resources__filters__search .select2-container--default .select2-selection--multiple .select2-selection__choice__display {
	font-size: .8064516129032259rem;
	font-size: var(--fz-ratio-power--minus-one);
}

.harm__related_resources__table {
	font-size: .8064516129032259rem;
	font-size: var(--fz-ratio-power--minus-one);
}

.harm__related_resources__dialog__item.harm__related_resources__dialog__item--tag span:not(.none_value),
.harm__related_resources__dialog__item.harm__related_resources__dialog__item--harm span:not(.none_value) {
	font-size: .8064516129032259rem;
	font-size: var(--fz-ratio-power--minus-one);
}

.harm__related_resources__dialog__term {
	font-size: .8064516129032259rem;
	font-size: var(--fz-ratio-power--minus-one);
}

.page_not_found__message {
	font-size: 1.24rem;
	font-size: var(--fz-ratio-power--one);
}

.positive_property__label__text {
	font-size: .6503642039542145rem;
	font-size: var(--fz-ratio-power--minus-two);
}

.positive_property__heading__label {
	font-size: .8064516129032259rem;
	font-size: var(--fz-ratio-power--minus-one);
}

.positive_property__related_harms__button {
	font-size: 1.24rem;
	font-size: var(--fz-ratio-power--one);
}

* + .positive_property__related_harms__button {
	margin-top: 1.86rem;
	margin-top: var(--sp-ratio-power--one);
}

.results__item--harm::after {
	font-size: .6503642039542145rem;
	font-size: var(--fz-ratio-power--minus-two);
}

}

@media screen and (min-width:1680px) {

:root {
	font-size: 19px;
}

}

@media only screen and (min-width:1701px) {

.graph__logo__anchor {
	position: absolute;
	z-index: auto;
	top: 0;
}

.graph__logo__anchor {
	transform: rotate(-90deg) translateX(-100%) translateY(-100%);
	transform-origin: 0 0;
}

.graph__logo__beta_signifier {
	position: absolute;
	z-index: auto;
	left: -2rem;
	bottom: -.8rem;
	transform: rotate(90deg) translateX(-100%) translateY(-100%);
	transform-origin: 0 0;
}

}

@media only screen and (max-width:1700px) {

.graph__logo__anchor {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	gap: .809rem;
	margin-bottom: .5393333333333333rem;
}

}

@media only screen and (max-width:1080px) {

.global_footer__logo_and_legal::before {
	content: "";
	position: absolute;
	z-index: auto;
	right: -50%;
	height: 2px;
	width: 9999px;
	background-color: #3c494e;
}

}

@keyframes reveal_from_right {

0% {
	transform: translateX(100%);
}

100% {
	transform: translateX(0);
}

}

@keyframes reveal_from_left {

0% {
	transform: translateX(-100%);
}

100% {
	transform: translateX(0);
}

}

@keyframes modal--open {

0% {
	opacity: 0;
}

100% {
	opacity: 1;
}

}

@keyframes modal--close {

0% {
	opacity: 1;
}

60% {
	opacity: 1;
}

100% {
	opacity: 0;
}

}

@keyframes action_anchor_interaction--upwards {

0% {
	transform: translateY(0);
}

50% {
	transform: translateY(-.3236rem);
}

100% {
	transform: translateY(0);
}

}

@keyframes action_anchor_interaction--forwards {

0% {
	transform: translateX(0);
}

50% {
	transform: translateX(.5393333333333333rem);
}

100% {
	transform: translateX(0);
}

}

@keyframes action_anchor_interaction--downwards {

0% {
	transform: translateY(0);
}

50% {
	transform: translateY(.3236rem);
}

100% {
	transform: translateY(0);
}

}

@keyframes action_anchor_interaction--backwards {

0% {
	transform: translateX(0);
}

50% {
	transform: translateX(-.5393333333333333rem);
}

100% {
	transform: translateX(0);
}

}

@keyframes fadein {

from {
	bottom: 0;
	opacity: 0;
}

to {
	bottom: 30px;
	opacity: 1;
}

}

@keyframes fadeout {

from {
	bottom: 30px;
	opacity: 1;
}

to {
	bottom: 0;
	opacity: 0;
}

}

